import type { RouteType } from "ui-kit-legacy";
import { lazy } from "ui-kit-legacy";

import { paths } from "../paths/groups";

export const routes: RouteType[] = [
  {
    path: paths.list,
    component: lazy(() => import("../pages/groups/List")),
  },
  {
    path: paths.create,
    component: lazy(() =>
      import("../pages/groups/Create").then((module) => ({
        default: module.CreateGroup,
      }))
    ),
  },
  {
    path: paths.createSubGroup,
    component: lazy(() =>
      import("../pages/groups/Create").then((module) => ({
        default: module.CreateSubGroup,
      }))
    ),
  },
  {
    path: paths.view,
    component: lazy(() => import("../pages/groups/View")),
  },
  {
    path: paths.edit,
    component: lazy(() => import("../pages/groups/Edit/BasicInfo")),
  },
  {
    path: paths.editGroupFields,
    component: lazy(() => import("../pages/groups/Edit/CustomFields")),
  },
];
