import {
  Divider,
  ExitToAppIcon,
  FormattedMessage,
  INavBarSection,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  NavBarSection,
  React,
  useConfigurationByUserGroupId,
  useGroupFromParamOptional,
  useLocation,
  useMemo,
} from "ui-kit-legacy";

import { useUserGroupsQuery } from "../../../graphql/__generated__";
import useLogout from "../../../hooks/useLogout";
import { generators } from "../../../paths";
import { getNavBarSections } from "./config";
import GroupFilter from "./GroupFilter";

export default function NavContent() {
  const {
    data: { groupId, groupDisplayName },
  } = useGroupFromParamOptional();
  const { data: configuration } = useConfigurationByUserGroupId(groupId);
  const { data, loading, error } = useUserGroupsQuery();

  const { logout } = useLogout();
  const location = useLocation();
  const currentPath = location.pathname;

  const groupIds = useMemo(() => {
    if (loading) return [];
    return data?.user_groups.map(({ id }) => id) || [];
  }, [data, loading]);

  const navBarSections: INavBarSection[] = getNavBarSections(
    currentPath,
    groupId,
    configuration
  );

  if (error) throw error;

  if (!groupId) {
    return (
      <>
        {currentPath !== generators.groups.list() ? <GroupFilter /> : null}
        <List>
          {navBarSections?.length > 0 && (
            <>
              {navBarSections.map(({ key, title, icon, items }) => (
                <NavBarSection
                  key={key}
                  title={title}
                  icon={icon}
                  items={items}
                  groupIds={groupIds}
                />
              ))}
              <Divider />
            </>
          )}
          <ListItem data-cy="logout" button onClick={() => logout()}>
            <ListItemIcon>
              <ExitToAppIcon />
            </ListItemIcon>
            <ListItemText primary={<FormattedMessage id="general.logout" />} />
          </ListItem>
        </List>
      </>
    );
  }

  return (
    <>
      <GroupFilter groupId={groupId} groupName={groupDisplayName} />
      <List>
        {navBarSections.map(({ key, title, icon, items }) => (
          <NavBarSection
            key={key}
            title={title}
            icon={icon}
            items={items}
            groupIds={groupId}
          />
        ))}
        <Divider />
        <ListItem data-cy="logout" button onClick={() => logout()}>
          <ListItemIcon>
            <ExitToAppIcon />
          </ListItemIcon>
          <ListItemText primary={<FormattedMessage id="general.logout" />} />
        </ListItem>
      </List>
    </>
  );
}
