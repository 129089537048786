import { isSameDomain } from "common";
import { useAuth, useCallback, useContext } from "ui-kit-legacy";

import { LogoutUrlContext } from "../contexts/LogoutUrlContext";

export default function useLogout() {
  const { getLogoutUrls } = useContext(LogoutUrlContext);
  const { logout: authLogout } = useAuth();

  const logout = useCallback(() => {
    const logoutUrls = getLogoutUrls();
    let count = logoutUrls.length;
    const returnTo = `${window.location.protocol}//${window.location.host}`;

    logoutUrls.forEach((inputLogoutUrl) => {
      ((logoutUrl: string) => {
        const logoutWin = window.open(
          logoutUrl,
          "_blank",
          "width=1,height=1,top=0,left=0"
        );
        if (logoutWin) {
          const cb = () => {
            count -= 1;
            logoutWin.close();
            if (count === 0) {
              logoutWin.close();
              authLogout({ returnTo });
            }
          };
          if (isSameDomain(window.location.href, logoutUrl)) {
            logoutWin.addEventListener("load", cb, { once: true });
          } else {
            setTimeout(cb, 2000);
          }
        } else {
          count -= 1;
        }
      })(inputLogoutUrl);
    });
    if (count === 0) {
      authLogout({ returnTo });
    }
  }, [authLogout, getLogoutUrls]);

  return {
    logout,
  };
}
