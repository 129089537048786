import type { ReactNode, ThemeType } from "ui-kit-legacy";
import { AppTopBar, React, makeStyles, useState } from "ui-kit-legacy";

import NavBar from "./NavBar";

interface DashboardLayoutProps {
  children?: ReactNode;
}

const useStyles = makeStyles((theme: ThemeType) => ({
  root: {
    display: "flex",
    height: "100vh",
    overflow: "hidden",
    width: "100%",
  },
  wrapper: {
    display: "flex",
    flex: "1 1 auto",
    overflow: "hidden",
    paddingTop: 64,
    [theme.breakpoints.up("lg")]: {
      paddingLeft: 256,
    },
  },
  contentContainer: {
    display: "flex",
    flex: "1 1 auto",
    overflowX: "hidden",
    overflowY: "auto",
    padding: theme.spacing(3),
  },
  content: {
    flex: "1 1 auto",
    height: "100%",
    overflowX: "hidden",
    overflowY: "auto",
  },
}));

const DashboardLayout = ({ children }: DashboardLayoutProps) => {
  const classes = useStyles();
  const [isMobileNavOpen, setMobileNavOpen] = useState<boolean>(false);

  return (
    <div className={classes.root}>
      <AppTopBar
        onMobileNavOpen={() => setMobileNavOpen(true)}
        data-cy="drawer"
      />
      <NavBar
        onMobileClose={() => setMobileNavOpen(false)}
        openMobile={isMobileNavOpen}
      />
      <div className={classes.wrapper}>
        <div className={classes.contentContainer}>
          <div className={classes.content}>{children}</div>
        </div>
      </div>
    </div>
  );
};

DashboardLayout.defaultProps = {
  children: null,
};

export default DashboardLayout;
