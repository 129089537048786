import { TablePermissions } from "common";
import {
  CreditCardIcon,
  FormattedMessage,
  GroupIcon,
  INavBarSection,
  LabelIcon,
  MeetingRoomIcon,
  React,
  SettingsIcon,
  generatePath,
} from "ui-kit-legacy";

import type { Scalars } from "../../../graphql/__generated__";
import { generators, paths } from "../../../paths";

function notEmpty<T>(value: T | null | undefined): value is T {
  return value !== null && value !== undefined;
}

export function getNavBarSections(
  currentPath: string,
  groupId?: string,
  configuration?: Scalars["jsonb"]
): INavBarSection[] {
  const getListPath = (pathGroup: { list: string; listAll: string }) =>
    groupId
      ? generatePath(pathGroup.list, { groupId })
      : generatePath(pathGroup.listAll);

  const nullForAllGroup = <T,>(input: T) => (groupId ? input : null);

  if (currentPath === generators.groups.list()) return [];

  const campaignMenu =
    configuration?.campaign_list && groupId
      ? {
          title: <FormattedMessage id="general.campaignManagement" />,
          icon: <CreditCardIcon />,
          key: "campaign-management",
          items: [
            {
              path: generators.campaigns.list({ groupId }),
              name: <FormattedMessage id="general.allCampaigns" />,
              key: "nav-item-campaign",
              minimumPermission: TablePermissions.CAMPAIGN_SELECT,
            },
          ],
        }
      : null;

  const analyticsMenu = configuration?.analytics_dashboard
    ? {
        title: <FormattedMessage id="general.analytics" />,
        icon: <CreditCardIcon />,
        key: "analytics-management",
        items: [
          {
            path: configuration?.analytics_dashboard,
            external: true,
            name: <FormattedMessage id="general.analyticsDashboard" />,
            key: "nav-item-analytics",
            minimumPermission: TablePermissions.DASHBOARD_VIEWER,
          },
        ],
      }
    : null;

  return [
    {
      title: <FormattedMessage id="users.title" />,
      icon: <GroupIcon />,
      key: "user-management",
      items: [
        {
          path: getListPath(paths.users),
          name: <FormattedMessage id="users.list" />,
          key: "nav-item-user-list",
          minimumPermission: TablePermissions.USER_DELETE,
        },
        {
          path: getListPath(paths.invites),
          name: <FormattedMessage id="users.invitationsList" />,
          key: "nav-item-user-invitation-list",
          minimumPermission: TablePermissions.INVITE_SELECT,
        },
        nullForAllGroup({
          path: groupId
            ? generators.invites.create({ groupId })
            : generators.home(),
          name: <FormattedMessage id="users.inviteNew" />,
          key: "nav-item-user-invite",
          minimumPermission: TablePermissions.INVITE_INSERT,
        }),
        nullForAllGroup({
          path: groupId
            ? generators.users.massEdit({ groupId })
            : generators.home(),
          name: <FormattedMessage id="users.massEdit" />,
          key: "nav-item-user-mass-edit",
          minimumPermission: TablePermissions.USER_DELETE,
        }),
      ].filter(notEmpty),
    },
    nullForAllGroup({
      title: <FormattedMessage id="general.groupSettings" />,
      icon: <SettingsIcon />,
      key: "group-settings",
      items: [
        {
          path: groupId
            ? generators.groups.view({ groupId })
            : generators.home(),
          name: <FormattedMessage id="general.general" />,
          key: "nav-item-general-settings",
          minimumPermission: TablePermissions.USER_GROUP_SELECT,
        },
        {
          path: groupId
            ? generators.groups.createSubGroup({ groupId })
            : generators.home(),
          name: <FormattedMessage id="groups.createSubGroup" />,
          key: "nav-item-group-create",
          minimumPermission: TablePermissions.USER_GROUP_INSERT,
        },
      ],
    }),
    {
      title: <FormattedMessage id="general.asset" />,
      icon: <MeetingRoomIcon />,
      key: "asset",
      items: [
        {
          path: getListPath(paths.assets),
          name: <FormattedMessage id="general.allAssets" />,
          key: "nav-item-asset",
          minimumPermission: TablePermissions.ASSET_UPDATE,
        },
        nullForAllGroup({
          path: groupId
            ? generators.assets.create({ groupId })
            : generators.home(),
          name: <FormattedMessage id="general.createAsset" />,
          key: "nav-item-asset-create",
          minimumPermission: TablePermissions.ASSET_INSERT,
        }),
        {
          path: getListPath(paths.assetGroups),
          name: <FormattedMessage id="general.allAssetGroups" />,
          key: "nav-item-asset-groups",
          minimumPermission: TablePermissions.ASSET_GROUP_UPDATE,
        },
        nullForAllGroup({
          path: groupId
            ? generators.assetGroups.create({ groupId })
            : generators.home(),
          name: <FormattedMessage id="general.createAssetGroup" />,
          key: "nav-item-asset-group-create",
          minimumPermission: TablePermissions.ASSET_GROUP_INSERT,
        }),
      ].filter(notEmpty),
    },
    {
      title: <FormattedMessage id="bookings" />,
      icon: <LabelIcon />,
      key: "bookings",
      items: [
        {
          path: getListPath(paths.bookings),
          name: <FormattedMessage id="bookingsList" />,
          key: "nav-item-bookings-list",
          minimumPermission: [
            TablePermissions.ASSET_BOOKING_SELECT_APPROVER,
            TablePermissions.ASSET_BOOKING_UPDATE_APPROVER,
            TablePermissions.ASSET_BOOKING_UPDATE,
          ],
        },
      ],
    },
    nullForAllGroup(campaignMenu),
    nullForAllGroup(analyticsMenu),
  ].filter(notEmpty);
}
