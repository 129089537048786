/** @jsxRuntime classic */
import "react-app-polyfill/ie11";
import "react-app-polyfill/stable";
import "./index.css";

import {
  Auth0ProviderWithHistory,
  ErrorScreen,
  bootstrapApp,
} from "ui-kit-legacy";

import App from "./App";
import config from "./config";
import en from "./i18n/en.json";
import th from "./i18n/th.json";

const {
  AUTH0_AUDIENCE,
  AUTH0_CLIENT_ID,
  AUTH0_DOMAIN,
  DEBUG,
  DATADOG_IS_ENABLED,
  DATADOG_SERVICE_NAME,
  DATADOG_CLIENT_TOKEN,
} = config;

bootstrapApp({
  App,
  Auth0Provider: Auth0ProviderWithHistory,
  Auth0ProviderOptions: {
    clientId: AUTH0_CLIENT_ID,
    domain: AUTH0_DOMAIN,
    audience: AUTH0_AUDIENCE,
    redirectUri: window.location.origin,
  },
  ErrorScreen,
  messages: { en, th },
  querySelector: "#root",
  DEBUG,
  datadogLoggingOptions: {
    enabled: DATADOG_IS_ENABLED,
    service: DATADOG_SERVICE_NAME,
    clientToken: DATADOG_CLIENT_TOKEN,
  },
});
