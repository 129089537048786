import type {
  ApolloConfig,
  Auth0Config,
  DatadogConfig,
  EnvironmentConfig,
  MediaConfig,
} from "ui-kit-legacy";
import {
  ApolloSchema,
  Auth0Schema,
  DatadogSchema,
  EnvironmentSchema,
  MediaSchema,
  Yup,
  getApolloConfig,
  getAuth0Config,
  getDatadogConfig,
  getEnvironmentConfig,
  getMediaConfig,
  validateConfig,
} from "ui-kit-legacy";

type AppConfig = EnvironmentConfig &
  Auth0Config &
  ApolloConfig &
  MediaConfig &
  DatadogConfig;

const schema: Yup.SchemaOf<AppConfig> = Yup.object()
  .concat(EnvironmentSchema)
  .concat(Auth0Schema)
  .concat(ApolloSchema)
  .concat(MediaSchema)
  .concat(DatadogSchema);

const envConfig = getEnvironmentConfig(process.env);

export default validateConfig<AppConfig>(
  {
    name: "Admin App",
    env: process.env,
    config: {
      ...envConfig,
      ...getApolloConfig(process.env),
      ...getAuth0Config(process.env),
      ...getMediaConfig(process.env),
      ...getDatadogConfig(process.env),
    },
    schema,
  },
  {
    log: envConfig.DEBUG,
  }
);
