import { React } from "ui-kit-legacy";

const defaultValue: {
  getLogoutUrls: () => string[];
  addLogoutUrl?: (v: string) => void;
} = {
  getLogoutUrls: () => [],
};

export const LogoutUrlContext = React.createContext(defaultValue);
