import {
  AppTopBar,
  LoadingScreen,
  React,
  SuspenseLayout as UiKitSuspenseLayout,
} from "ui-kit-legacy";

export default function SuspenseLayout() {
  return (
    <UiKitSuspenseLayout appBar={<AppTopBar />}>
      <LoadingScreen />
    </UiKitSuspenseLayout>
  );
}
