import { generatePath } from "ui-kit-legacy";

export const paths = {
  listAll: "/groups",
  list: "/groups",
  create: "/groups/create",
  createSubGroup: "/:groupId/groups/create",
  view: "/:groupId/settings",
  edit: "/:groupId/edit",
  editGroupFields: "/:groupId/edit/group-fields",
};

export const generators = {
  listAll: () => generatePath(paths.listAll),
  list: () => generatePath(paths.list),
  create: () => generatePath(paths.create),
  createSubGroup: ({ groupId }: Record<"groupId", string>) =>
    generatePath(paths.createSubGroup, { groupId }),
  view: ({ groupId }: Record<"groupId", string>) =>
    generatePath(paths.view, { groupId }),
  edit: ({ groupId }: Record<"groupId", string>) =>
    generatePath(paths.edit, { groupId }),
  editGroupFields: ({ groupId }: Record<"groupId", string>) =>
    generatePath(paths.editGroupFields, { groupId }),
};
