import type { RouteType } from "ui-kit-legacy";
import { NotFoundPage, React, Redirect } from "ui-kit-legacy";

import DashboardLayout from "../layouts/Dashboard";
import { generators, paths } from "../paths";
import { routes as assetGroupsRoutes } from "./assetGroups";
import { routes as assetsRoutes } from "./assets";
import { routes as bookingsRoutes } from "./bookings";
import { routes as campaignsRoutes } from "./campaigns";
import { routes as groupsRoutes } from "./groups";
import { routes as invitesRoutes } from "./invites";
import { routes as usersRoutes } from "./users";

const getApplicationRoutes = (routesConfig: RouteType[]): RouteType[] => {
  const definedRoutes = routesConfig.map((route) => ({
    ...route,
    exact: route?.exact || true,
    layout: route?.layout || DashboardLayout,
  }));

  return [
    {
      path: paths.home,
      exact: true,
      component: () => <Redirect to={generators.groups.list()} />,
    },
    ...definedRoutes,
    {
      path: "*",
      component: () => <NotFoundPage />,
    },
  ];
};

const applicationRoutes: RouteType[] = [
  ...groupsRoutes,
  ...assetGroupsRoutes,
  ...assetsRoutes,
  ...invitesRoutes,
  ...usersRoutes,
  ...bookingsRoutes,
  ...campaignsRoutes,
];

export default getApplicationRoutes(applicationRoutes);
