import { ApolloProvider } from "@apollo/client";
import {
  AppProvider,
  React,
  RenderRoutes,
  useCallback,
  useState,
} from "ui-kit-legacy";

import SuspenseLayout from "./components/SuspenseLayout";
import config from "./config";
import { LogoutUrlContext } from "./contexts/LogoutUrlContext";
import routes from "./routing";

const { DEBUG, GRAPHQL_URL } = config;

export default function App() {
  const [logoutUrlSet, setLogoutUrlSet] = useState(new Set<string>());

  const addLogoutUrl = useCallback(
    (url: string) => setLogoutUrlSet((prev) => prev.add(url)),
    [setLogoutUrlSet]
  );

  const getLogoutUrls = useCallback(() => {
    const arr: string[] = [];
    logoutUrlSet.forEach((v) => arr.push(v));
    return arr;
  }, [logoutUrlSet]);

  return (
    <AppProvider
      uri={GRAPHQL_URL}
      debug={DEBUG}
      name="admin"
      ApolloProvider={ApolloProvider}
    >
      <LogoutUrlContext.Provider value={{ getLogoutUrls, addLogoutUrl }}>
        <RenderRoutes
          routes={routes}
          FallbackLayoutComponent={SuspenseLayout}
        />
      </LogoutUrlContext.Provider>
    </AppProvider>
  );
}
